<template>
  <div class="py-8 px-9 border-2 border-gray-lighter rounded-lg shadow-lg">
    <RatingInput :canRate="canRate" v-model:score="score" v-model:comment="comment" />
    <div class="flex flex-col">
      <a-alert v-if="showSubmitAlert" variant="warning" class="mt-4">
        <div class="prose" v-html="t('submitAlert')"></div>
      </a-alert>
      <a-button
        @click="createRating()"
        :disabled="score === null || !canRate"
        :variant="showSubmitAlert ? 'danger' : 'primary'"
        class="ml-auto mt-8"
      >
        {{ t('submit') }}
      </a-button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRef, watch } from '@vue/runtime-core';
import { useStore } from '@/store/lib/store';
import { authStore } from '@/store/auth';
import { programStore } from '@/store/program';
import { useI18n } from 'vue-i18n';
import { injectToasts } from '@/components/common/toasts/toasts';
import { logEvent } from '@/services/amplitude';

import RatingInput from '@/components/program/RatingInput.vue';

export default defineComponent({
  name: 'UnassignedRatingCard',
  props: {
    submissionId: { type: String, required: true },
    canRate: { type: Boolean, default: false }
  },
  components: {
    RatingInput
  },
  emits: ['ratingSubmitted'],
  setup(props, { emit }) {
    const auth = useStore(authStore);
    const program = useStore(programStore);
    const i18n = useI18n();
    const toasts = injectToasts();

    const currentUser = computed(() => auth.user);
    const isSchoolAdmin = computed(() => auth.isSchoolAdmin);

    const comment = ref<string | null>(null);
    const score = ref<number | null>(null);

    const showSubmitAlert = ref(false);

    async function createRating() {
      if (score.value === null) {
        throw new Error('Cannot submit a rating without the score');
      }
      if (!isSchoolAdmin.value && !showSubmitAlert.value) {
        showSubmitAlert.value = true;
        return;
      }

      await program.createScoredRating.run({
        submissionId: props.submissionId,
        score: score.value,
        comment: comment.value
      });

      if (program.createScoredRating.error) {
        toasts.push({ title: i18n.t('submitError'), variant: 'danger' });
        throw program.createScoredRating.error;
      }

      toasts.push({ title: i18n.t('submitSuccess'), variant: 'success' });
      emit('ratingSubmitted');

      const currentSubmission = program.submissions?.find(
        s => s.id === props.submissionId
      );

      logEvent({
        eventName: 'SS_ASSIGNED_APP_RATING',
        userProperties: {
          userID: auth.user?.id
        },
        eventProperties: {
          applicantUserId: currentSubmission?.user?.id,
          hasComment: comment.value !== null,
          snapshotScore: score.value,
          reviewerUserId: currentUser.value?.id,
          timeToRate: 0
        }
      });
    }

    watch(
      toRef(props, 'submissionId'),
      () => {
        score.value = null;
        comment.value = null;
      },
      { immediate: true }
    );

    return {
      t: i18n.t,
      score,
      comment,
      currentUser,
      isSchoolAdmin,
      currentTest: computed(() => program.currentTest),
      showSubmitAlert,
      createRating
    };
  }
});
</script>

<i18n>
{
  "en": {
    "submit": "Submit and go to next applicant",
    "submitAlert": "<h4>Do you want to submit your review?</h4><p>Select submit review to confirm and go to the next applicant. You cannot edit a review once submitted.</p>",
    "submitError": "Failed to submit",
    "submitSuccess": "Submitted successfully"
  },
  "fr": {
    "submit": "Soumettre et aller au candidat suivant",
    "submitAlert": "<h4>Voulez-vous soumettre votre évaluation ?</h4><p>Sélectionnez soumettre pour confirmer et aller au candidat suivant. Vous ne pouvez pas modifier une évaluation une fois que vous l'avez soumise.</p>",
    "submitError": "Non soumis",
    "submitSuccess": "Soumis avec succès"
  }
}
</i18n>
