<template>
  <div
    role="region"
    :aria-label="t('srCarouselLabel')"
    tabindex="0"
    class="carousel-container"
  >
    <button
      :aria-label="t('srPreviousSlideLabel')"
      @click="previousSlide()"
      class="chevron-button"
    >
      <a-chevron class="w-10 transform rotate-180 text-gray-dark" aria-hidden="true" />
    </button>

    <div class="overflow-hidden">
      <ul
        class="relative single-slide-container"
        :style="{ transform: amountToShift }"
        @transitionend="onTransitionFinished()"
      >
        <li
          v-for="(item, i) in augmentedAnswers"
          :key="item.answer.id"
          class="flex flex-col justify-between"
        >
          <div>
            <div class="font-bold text-blue-darkest text-xl mb-2">
              {{
                t('questionTitle', {
                  questionNum: i + 1,
                  totalQuestions: augmentedAnswers.length
                })
              }}
            </div>
            <p class="text-gray-darker">{{ item.questionText }}</p>
          </div>
          <div class="w-full md:w-4/5 lg:w-3/4 mx-auto mt-10">
            <div class="relative" style="padding-bottom: 75%">
              <VideoPlayer
                controls
                :ref="el => setVideoPlayerInstance(el, i)"
                :src="item.videoUrl"
                class="absolute w-full"
                :class="{ hidden: !transitioning && currentSlide !== i }"
                @ended="$emit('answerWatched', item.answer.testQuestionId)"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>

    <button
      :aria-label="t('srNextSlideLabel')"
      @click="nextSlide()"
      class="chevron-button"
    >
      <a-chevron class="w-10 text-gray-dark" aria-hidden="true" />
    </button>

    <ul class="col-start-2 justify-self-center flex slide-indicator">
      <li v-for="(item, i) in augmentedAnswers" :key="item.answer.id">
        <button
          :aria-label="t('srSlideIndicatorLabel', { num: i + 1 })"
          class="w-3 h-3 mx-1 rounded-full bg-blue-lighter"
          :class="{ 'active-slide': currentSlide === i }"
          @click="currentSlide = i"
        ></button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { TestQuestion, Answer } from '@/interfaces/interfaces';
import { computed, DeepReadonly, defineComponent, PropType, ref, watch } from 'vue';
import { useI18n } from '@/services/i18n';
import VideoPlayer from '@/components/common/VideoPlayer.vue';

export default defineComponent({
  name: 'Carousel',
  components: {
    VideoPlayer
  },
  props: {
    answers: {
      required: true,
      type: Array as PropType<Answer[]>
    },
    videoUrls: {
      required: true,
      type: Array as PropType<Array<{ fileName: string; url: string }>>
    },
    testQuestions: {
      required: true,
      type: Array as PropType<DeepReadonly<TestQuestion[]>>
    }
  },
  emits: ['answerWatched'],

  setup(props) {
    const currentSlide = ref(0);

    const videoPlayers: Array<InstanceType<typeof VideoPlayer>> = [];

    function setVideoPlayerInstance(el: object | null, i: number) {
      videoPlayers[i] = el as InstanceType<typeof VideoPlayer>;
    }

    function getQuestionText(textQuestionId: string) {
      const questionObj = props.testQuestions.find(t => t.id === textQuestionId);

      return questionObj?.question.text;
    }

    function getVideoUrl(fileName: string) {
      const videoObj = props.videoUrls.find(v => v.fileName === fileName);

      return videoObj?.url;
    }

    const augmentedAnswers = computed(() => {
      return props.answers.map(a => ({
        answer: a,
        questionText: getQuestionText(a.testQuestionId),
        videoUrl: getVideoUrl(a.videoFilename)
      }));
    });

    const amountToShift = computed(
      () => `translateX(-${(100 / augmentedAnswers.value.length) * currentSlide.value}%`
    );

    const transitioning = ref(false);

    watch(currentSlide, (newValue, oldValue) => {
      videoPlayers[oldValue].pause();
      transitioning.value = true;
    });

    watch(props, () => {
      currentSlide.value = 0;
    });

    function onTransitionFinished() {
      transitioning.value = false;
    }

    function nextSlide() {
      currentSlide.value === augmentedAnswers.value.length - 1
        ? (currentSlide.value = 0)
        : currentSlide.value++;
    }

    function previousSlide() {
      currentSlide.value === 0
        ? (currentSlide.value = augmentedAnswers.value.length - 1)
        : currentSlide.value--;
    }

    return {
      t: useI18n().t,
      currentSlide,
      amountToShift,
      nextSlide,
      previousSlide,
      onTransitionFinished,
      transitioning,
      augmentedAnswers,
      setVideoPlayerInstance
    };
  }
});
</script>

<style lang="postcss" scoped>
button:focus,
.carousel-container:focus {
  @apply outline-none;
}

.carousel-container {
  grid-template-columns: 2rem 1fr 2rem;
  grid-template-rows: 1fr 2rem;
  @apply grid gap-2 w-10/12 mx-auto;

  &:focus-visible {
    @apply ring-2 ring-blue;
  }
}

.single-slide-container {
  @apply grid grid-flow-col grid-cols-3;
  width: 300%;
  transition: transform 0.5s ease;
}

.chevron-button {
  @apply self-center;

  & svg {
    @apply transition-colors;
  }

  &:focus-visible svg {
    @apply ring-2 ring-blue;
  }

  &:-moz-focusring svg {
    @apply ring-2 ring-blue;
  }

  &:hover svg {
    @apply text-blue;
  }
}

.slide-indicator button {
  &:focus-visible {
    @apply ring-2 ring-blue;
  }

  &:-moz-focusring {
    @apply ring-2 ring-blue;
  }

  &:hover {
    @apply bg-gray;
  }

  &.active-slide {
    @apply bg-blue;

    &:hover {
      @apply bg-gray-dark;
    }

    &:focus-visible {
      @apply ring-blue-darkest;
    }

    &:-moz-focusring {
      @apply ring-blue-darkest;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "questionTitle": "Question {questionNum} of {totalQuestions}",
    "srCarouselLabel": "Question and video response slider",
    "srNextSlideLabel": "Next slide",
    "srPreviousSlideLabel": "Previous slide",
    "srSlideIndicatorLabel": "Go to slide {num}"
  },
  "fr": {
    "questionTitle": "Question {questionNum} sur {totalQuestions}",
    "srCarouselLabel": "Curseur questions et réponses vidéos",
    "srNextSlideLabel": "Diapositive suivante",
    "srPreviousSlideLabel": "Diapositive précédente",
    "srSlideIndicatorLabel": "Aller à la diapositive {num}"
  }
}
</i18n>
