export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "applicantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant ID"])},
        "assignedReviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Reviewer(s)"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "failedToUnassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not unassign this applicant. Please try again"])},
        "nextApplicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to next applicant"])},
        "noReviewersAssignedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no reviewers assigned to this applicant yet. You will be automatically assigned to this applicant if you submit a rating now."])},
        "previousApplicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous applicant"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
        "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed"])},
        "reviewerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successfullyUnassignedDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have successfully unassigned ", _interpolate(_named("raterName")), "."])},
        "unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassign"])},
        "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you"])}
      },
      "fr": {
        "applicantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du candidat"])},
        "assignedReviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examinateur(s) assigné(s)"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
        "failedToUnassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas pu retirer ce candidat. Veuillez essayer de nouveau."])},
        "nextApplicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidat suivant"])},
        "noReviewersAssignedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas encore d'examinateurs assignés à ce candidat. Vous serez automatiquement assigné à ce candidant si vous soumttez une évalution maintenant."])},
        "previousApplicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidat précédent"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluer"])},
        "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évalué"])},
        "reviewerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examinateur :"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
        "successfullyUnassignedDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez bien retiré ", _interpolate(_named("raterName")), "."])},
        "unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
        "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moi"])}
      }
    }
  })
}
