
import { computed, defineComponent, PropType } from '@vue/runtime-core';
import { programStore } from '@/store/program';
import { useI18n } from 'vue-i18n';
import { logEvent } from '@/services/amplitude';
import { useStore } from '@/store/lib/store';
import { authStore } from '@/store/auth';

export default defineComponent({
  name: 'RatingInput',
  props: {
    comment: { type: String as PropType<string | null> },
    score: { type: Number as PropType<number | null> },
    canRate: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  emits: ['update:comment', 'update:score'],
  setup(props, { emit }) {
    const program = useStore(programStore);
    const auth = useStore(authStore);
    const i18n = useI18n();

    const currentUser = computed(() => auth.user);
    const scale = computed(() => program.currentTest?.scale);

    const wrappedComment = computed(() => props.comment ?? undefined);
    const wrappedScore = computed(() => props.score ?? undefined);

    function emitComment(event: Event) {
      emit('update:comment', (event.target as HTMLTextAreaElement).value || null);
    }

    function emitScore(value: number) {
      emit('update:score', props.score === value ? null : value);
    }

    return {
      t: i18n.t,
      currentTest: computed(() => program.currentTest),
      currentUser,
      scale,
      logEvent,
      wrappedComment,
      wrappedScore,
      emitComment,
      emitScore
    };
  }
});
