<template>
  <div v-if="currentSubmission && currentTest">
    <header class="w-11/12 md:w-5/6 mt-6 mb-12 mx-auto">
      <div class="flex justify-between mb-10">
        <a-card-link
          :to="{ name: 'program.submission', params: { submissionId: prevSubmission } }"
          :disabled="prevSubmission === null"
          left-chevron
          @click="
            logEvent({
              eventName: 'SS_CLICKED_TO_VIEW_OTHER_RESPONSE',
              userProperties: {
                userID: currentUser?.id
              },
              eventProperties: {
                applicantUserId: currentSubmission?.submission?.user?.id,
                direction: 'Prev'
              }
            })
          "
        >
          {{ t('previousApplicant') }}
        </a-card-link>

        <a-card-link
          :to="{ name: 'program.submission', params: { submissionId: nextSubmission } }"
          :disabled="nextSubmission === null"
          @click="
            logEvent({
              eventName: 'SS_CLICKED_TO_VIEW_OTHER_RESPONSE',
              userProperties: {
                userID: currentUser?.id
              },
              eventProperties: {
                applicantUserId: currentSubmission?.submission?.user?.id,
                direction: 'Next'
              }
            })
          "
        >
          {{ t('nextApplicant') }}
        </a-card-link>
      </div>

      <h1 class="font-bold text-3xl text-blue-darkest">
        {{ currentSubmission.submission?.user?.profile?.name }}
      </h1>
      <ul class="flex mt-4">
        <li class="mr-10">
          {{ currentSubmission.submission?.user?.emails?.[0]?.address }}
        </li>

        <li class="flex items-center">
          {{ t('applicantId') }}: {{ currentSubmission.submission?.userId }}
        </li>
      </ul>
    </header>

    <main class="w-11/12 md:w-5/6 mx-auto" :class="{ 'xl:flex': isSchoolAdmin }">
      <section class="mb-12 xl:w-3/4">
        <div class="border border-gray-lighter rounded-lg shadow-md px-7 py-6">
          <Carousel
            v-if="answers && videoUrls && currentTest.testQuestions !== undefined"
            :answers="answers"
            :video-urls="videoUrls"
            :test-questions="currentTest.testQuestions"
            @answer-watched="addWatchedAnswer"
          />
        </div>

        <UnassignedRatingCard
          class="mt-8"
          v-if="useUnassignedRatingCard"
          :submission-id="submissionId"
          :can-rate="allAnswersWatched"
          @rating-submitted="goToNextSubmission()"
        />

        <AssignedRatingCard
          v-for="rating in orderedRatings"
          :submission-id="submissionId"
          :key="rating.id"
          :can-rate="allAnswersWatched"
          :rating="rating"
          @rating-submitted="goToNextSubmission()"
          class="mt-8"
        ></AssignedRatingCard>
      </section>

      <section v-if="isSchoolAdmin" class="ml-6 xl:w-1/4 xl:flex-none">
        <h2 class="text-blue-darkest font-bold text-lg mb-4">
          {{ t('assignedReviewers') }}
        </h2>
        <div v-if="!isSubmissionAssigned">
          <a-alert icon>{{ t('noReviewersAssignedAlert') }}</a-alert>
        </div>
        <ul v-else>
          <li
            v-for="rater in currentSubmission.raters"
            :key="rater.id"
            class="border-b border-gray-lighter text-sm text-gray-darker py-3"
          >
            <span>{{ rater.profile?.name }}</span>
            <span class="font-bold" v-if="currentUser?.id === rater.id">
              ({{ t('you') }})
            </span>
            <div class="inline-block float-right">
              <p v-if="isRatingRated(rater.id)">{{ t('reviewed') }}</p>
              <button
                v-else
                class="link focus:outline-none"
                @click="unassignRater(rater)"
              >
                {{ t('unassign') }}
              </button>
            </div>
          </li>
        </ul>
      </section>
    </main>
  </div>
</template>

<script lang="ts">
import { injectApiClient } from '@/services/apiClient';
import { injectRouter } from '@/router';
import { injectToasts } from '@/components/common/toasts/toasts';
import { Answer, User } from '@/interfaces/interfaces';
import { computed, defineComponent, ref, watch, toRef, DeepReadonly } from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { programStore } from '@/store/program';
import { authStore } from '@/store/auth';
import { logEvent } from '@/services/amplitude';

import Carousel from '@/components/common/Carousel.vue';
import UnassignedRatingCard from '@/components/program/UnassignedRatingCard.vue';
import AssignedRatingCard from '@/components/program/AssignedRatingCard.vue';

export default defineComponent({
  name: 'ProgramSubmissionRating',

  components: {
    Carousel,
    UnassignedRatingCard,
    AssignedRatingCard
  },

  props: {
    submissionId: { type: String, required: true }
  },

  setup(props) {
    const apiClient = injectApiClient();
    const router = injectRouter();
    const toasts = injectToasts();
    const i18n = useI18n();
    const program = useStore(programStore);
    const auth = useStore(authStore);

    const currentUser = computed(() => auth.user);

    const answers = ref<Answer[] | null>(null);
    const videoUrls = ref<Array<{ fileName: string; url: string }> | null>(null);
    const watchedAnswers = ref(new Set<string>());

    const allAnswersWatched = computed(
      () => answers.value !== null && answers.value.length === watchedAnswers.value.size
    );

    const isSchoolAdmin = computed(() => auth.isSchoolAdmin);
    const useMultiReviewers = computed(() => program.useMultiReviewers);

    const currentSubmissionIndex = computed(() =>
      program.filteredSubmissions?.findIndex(s => s.submission.id === props.submissionId)
    );

    const currentSubmission = computed(() =>
      currentSubmissionIndex.value === undefined
        ? undefined
        : program.filteredSubmissions?.[currentSubmissionIndex.value]
    );

    const submissionRatings = computed(
      () => currentSubmission.value && currentSubmission.value.submission.ratings
    );

    const isSubmissionAssigned = computed(
      () => submissionRatings.value !== undefined && submissionRatings.value.length > 0
    );

    const isAssignedToCurrentUser = computed(
      () =>
        submissionRatings.value !== undefined &&
        submissionRatings.value.some(
          r => currentUser.value !== null && r.userId === currentUser.value.id
        )
    );

    const currentUserRating = computed(() => {
      return submissionRatings.value?.find(r => {
        if (currentUser.value !== null) {
          return r.userId === currentUser.value.id;
        }
      });
    });

    const useUnassignedRatingCard = computed(() => {
      return (
        (!isAssignedToCurrentUser.value && useMultiReviewers.value) ||
        !isSubmissionAssigned.value
      );
    });

    const orderedRatings = computed(() => {
      if (submissionRatings.value === undefined) {
        return [];
      } else if (!isSchoolAdmin.value) {
        return currentUserRating.value !== undefined ? [currentUserRating.value] : [];
      } else if (currentUserRating.value === undefined) {
        return submissionRatings.value;
      } else {
        const ratings = submissionRatings.value.filter(
          r => currentUser.value !== null && r.userId !== currentUser.value.id
        );
        ratings.unshift(currentUserRating.value);
        return ratings;
      }
    });

    const nextSubmission = computed(() => {
      if (
        program.filteredSubmissions === undefined ||
        currentSubmissionIndex.value === undefined
      ) {
        throw new Error('No current index or filtered submissions found');
      }
      return (
        program.filteredSubmissions[currentSubmissionIndex.value + 1]?.submission?.id ??
        null
      );
    });

    const prevSubmission = computed(() => {
      if (
        program.filteredSubmissions === undefined ||
        currentSubmissionIndex.value === undefined
      ) {
        throw new Error('No current index or filtered submissions found');
      }
      return (
        program.filteredSubmissions[currentSubmissionIndex.value - 1]?.submission?.id ??
        null
      );
    });

    function getRatingScore(raterId: string) {
      const rating = currentSubmission.value?.submission.ratings?.find(
        r => r.userId === raterId
      );
      return rating?.score ?? null;
    }

    function isRatingRated(raterId: string) {
      return getRatingScore(raterId) !== null;
    }

    function addWatchedAnswer(questionId: string) {
      watchedAnswers.value.add(questionId);
    }

    async function goToNextSubmission() {
      if (nextSubmission.value === null) {
        return;
      }

      await router.push({
        name: 'program.submission',
        params: { submissionId: nextSubmission.value }
      });
    }

    async function unassignRater(rater: DeepReadonly<User>) {
      if (currentSubmission.value?.submission.ratings !== undefined) {
        const rating = currentSubmission.value.submission.ratings?.find(
          r => r.userId === rater.id
        );
        if (rating !== undefined) {
          await program.unassignRater.run(
            rating?.id,
            currentSubmission.value.submission.id
          );

          if (program.unassignRater.error !== null) {
            toasts.push({
              title: i18n.t('error'),
              content: i18n.t('failedToUnassign'),
              variant: 'danger'
            });
            throw program.unassignRater.error;
          } else {
            toasts.push({
              title: i18n.t('success'),
              content: i18n.t('successfullyUnassignedDescription', {
                raterName: rater.profile?.name
              }),
              variant: 'success'
            });
          }
        }
      }
    }

    watch(
      toRef(props, 'submissionId'),
      async () => {
        if (currentSubmission.value !== undefined) {
          const data = await apiClient.getAnswersFromSubmission(
            currentSubmission.value.submission.id
          );
          answers.value = data.answers;
          videoUrls.value = data.videoUrls;
          watchedAnswers.value = new Set();
        }
      },
      { immediate: true }
    );

    return {
      t: i18n.t,
      currentUser,
      isAssignedToCurrentUser,
      orderedRatings,
      logEvent,
      currentTest: program.currentTest,
      currentSubmission,
      answers,
      videoUrls,
      allAnswersWatched,
      addWatchedAnswer,
      prevSubmission,
      nextSubmission,
      unassignRater,
      isRatingRated,
      getRatingScore,
      isSubmissionAssigned,
      isSchoolAdmin,
      useMultiReviewers,
      useUnassignedRatingCard,
      goToNextSubmission
    };
  }
});
</script>

<i18n>
{
  "en": {
    "applicantId": "Applicant ID",
    "assignedReviewers": "Assigned Reviewer(s)",
    "error": "Error",
    "failedToUnassign": "We could not unassign this applicant. Please try again",
    "nextApplicant": "Go to next applicant",
    "noReviewersAssignedAlert": "There are no reviewers assigned to this applicant yet. You will be automatically assigned to this applicant if you submit a rating now.",
    "previousApplicant": "Previous applicant",
    "review": "Review",
    "reviewed": "Reviewed",
    "reviewerName": "Reviewer:",
    "success": "Success",
    "successfullyUnassignedDescription": "You have successfully unassigned {raterName}.",
    "unassign": "Unassign",
    "you": "you"
  },
  "fr": {
    "applicantId": "ID du candidat",
    "assignedReviewers": "Examinateur(s) assigné(s)",
    "error": "Erreur",
    "failedToUnassign": "Nous n'avons pas pu retirer ce candidat. Veuillez essayer de nouveau.",
    "nextApplicant": "Candidat suivant",
    "noReviewersAssignedAlert": "Il n'y a pas encore d'examinateurs assignés à ce candidat. Vous serez automatiquement assigné à ce candidant si vous soumttez une évalution maintenant.",
    "previousApplicant": "Candidat précédent",
    "review": "Évaluer",
    "reviewed": "Évalué",
    "reviewerName": "Examinateur :",
    "success": "Succès",
    "successfullyUnassignedDescription": "Vous avez bien retiré {raterName}.",
    "unassign": "Retirer",
    "you": "moi"
  }
}
</i18n>
