
import { injectApiClient } from '@/services/apiClient';
import { injectRouter } from '@/router';
import { injectToasts } from '@/components/common/toasts/toasts';
import { Answer, User } from '@/interfaces/interfaces';
import { computed, defineComponent, ref, watch, toRef, DeepReadonly } from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { programStore } from '@/store/program';
import { authStore } from '@/store/auth';
import { logEvent } from '@/services/amplitude';

import Carousel from '@/components/common/Carousel.vue';
import UnassignedRatingCard from '@/components/program/UnassignedRatingCard.vue';
import AssignedRatingCard from '@/components/program/AssignedRatingCard.vue';

export default defineComponent({
  name: 'ProgramSubmissionRating',

  components: {
    Carousel,
    UnassignedRatingCard,
    AssignedRatingCard
  },

  props: {
    submissionId: { type: String, required: true }
  },

  setup(props) {
    const apiClient = injectApiClient();
    const router = injectRouter();
    const toasts = injectToasts();
    const i18n = useI18n();
    const program = useStore(programStore);
    const auth = useStore(authStore);

    const currentUser = computed(() => auth.user);

    const answers = ref<Answer[] | null>(null);
    const videoUrls = ref<Array<{ fileName: string; url: string }> | null>(null);
    const watchedAnswers = ref(new Set<string>());

    const allAnswersWatched = computed(
      () => answers.value !== null && answers.value.length === watchedAnswers.value.size
    );

    const isSchoolAdmin = computed(() => auth.isSchoolAdmin);
    const useMultiReviewers = computed(() => program.useMultiReviewers);

    const currentSubmissionIndex = computed(() =>
      program.filteredSubmissions?.findIndex(s => s.submission.id === props.submissionId)
    );

    const currentSubmission = computed(() =>
      currentSubmissionIndex.value === undefined
        ? undefined
        : program.filteredSubmissions?.[currentSubmissionIndex.value]
    );

    const submissionRatings = computed(
      () => currentSubmission.value && currentSubmission.value.submission.ratings
    );

    const isSubmissionAssigned = computed(
      () => submissionRatings.value !== undefined && submissionRatings.value.length > 0
    );

    const isAssignedToCurrentUser = computed(
      () =>
        submissionRatings.value !== undefined &&
        submissionRatings.value.some(
          r => currentUser.value !== null && r.userId === currentUser.value.id
        )
    );

    const currentUserRating = computed(() => {
      return submissionRatings.value?.find(r => {
        if (currentUser.value !== null) {
          return r.userId === currentUser.value.id;
        }
      });
    });

    const useUnassignedRatingCard = computed(() => {
      return (
        (!isAssignedToCurrentUser.value && useMultiReviewers.value) ||
        !isSubmissionAssigned.value
      );
    });

    const orderedRatings = computed(() => {
      if (submissionRatings.value === undefined) {
        return [];
      } else if (!isSchoolAdmin.value) {
        return currentUserRating.value !== undefined ? [currentUserRating.value] : [];
      } else if (currentUserRating.value === undefined) {
        return submissionRatings.value;
      } else {
        const ratings = submissionRatings.value.filter(
          r => currentUser.value !== null && r.userId !== currentUser.value.id
        );
        ratings.unshift(currentUserRating.value);
        return ratings;
      }
    });

    const nextSubmission = computed(() => {
      if (
        program.filteredSubmissions === undefined ||
        currentSubmissionIndex.value === undefined
      ) {
        throw new Error('No current index or filtered submissions found');
      }
      return (
        program.filteredSubmissions[currentSubmissionIndex.value + 1]?.submission?.id ??
        null
      );
    });

    const prevSubmission = computed(() => {
      if (
        program.filteredSubmissions === undefined ||
        currentSubmissionIndex.value === undefined
      ) {
        throw new Error('No current index or filtered submissions found');
      }
      return (
        program.filteredSubmissions[currentSubmissionIndex.value - 1]?.submission?.id ??
        null
      );
    });

    function getRatingScore(raterId: string) {
      const rating = currentSubmission.value?.submission.ratings?.find(
        r => r.userId === raterId
      );
      return rating?.score ?? null;
    }

    function isRatingRated(raterId: string) {
      return getRatingScore(raterId) !== null;
    }

    function addWatchedAnswer(questionId: string) {
      watchedAnswers.value.add(questionId);
    }

    async function goToNextSubmission() {
      if (nextSubmission.value === null) {
        return;
      }

      await router.push({
        name: 'program.submission',
        params: { submissionId: nextSubmission.value }
      });
    }

    async function unassignRater(rater: DeepReadonly<User>) {
      if (currentSubmission.value?.submission.ratings !== undefined) {
        const rating = currentSubmission.value.submission.ratings?.find(
          r => r.userId === rater.id
        );
        if (rating !== undefined) {
          await program.unassignRater.run(
            rating?.id,
            currentSubmission.value.submission.id
          );

          if (program.unassignRater.error !== null) {
            toasts.push({
              title: i18n.t('error'),
              content: i18n.t('failedToUnassign'),
              variant: 'danger'
            });
            throw program.unassignRater.error;
          } else {
            toasts.push({
              title: i18n.t('success'),
              content: i18n.t('successfullyUnassignedDescription', {
                raterName: rater.profile?.name
              }),
              variant: 'success'
            });
          }
        }
      }
    }

    watch(
      toRef(props, 'submissionId'),
      async () => {
        if (currentSubmission.value !== undefined) {
          const data = await apiClient.getAnswersFromSubmission(
            currentSubmission.value.submission.id
          );
          answers.value = data.answers;
          videoUrls.value = data.videoUrls;
          watchedAnswers.value = new Set();
        }
      },
      { immediate: true }
    );

    return {
      t: i18n.t,
      currentUser,
      isAssignedToCurrentUser,
      orderedRatings,
      logEvent,
      currentTest: program.currentTest,
      currentSubmission,
      answers,
      videoUrls,
      allAnswersWatched,
      addWatchedAnswer,
      prevSubmission,
      nextSubmission,
      unassignRater,
      isRatingRated,
      getRatingScore,
      isSubmissionAssigned,
      isSchoolAdmin,
      useMultiReviewers,
      useUnassignedRatingCard,
      goToNextSubmission
    };
  }
});
