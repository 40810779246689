<template>
  <h3 class="font-bold text-blue-darkest" id="comments">
    {{ t('comments') }}
  </h3>
  <textarea
    aria-labelledby="comments"
    maxlength="2000"
    class="textarea"
    :placeholder="t('commentsPlaceholder')"
    :disabled="disabled"
    :value="wrappedComment"
    @input="emitComment($event)"
  ></textarea>
  <div class="border border-gray-lighter rounded-lg shadow py-8 px-7 mb-2">
    <div class="flex">
      <h3 class="font-bold text-blue-darkest">{{ t('scoreTitle') }}</h3>
      <router-link
        :to="{
          name: 'program.rating-scale',
          params: { testId: currentTest?.id }
        }"
        target="_blank"
        class="new-tab-link link ml-auto"
        @click="
          logEvent({
            eventName: 'SS_VIEWED_RATING_HELPER',
            userProperties: {
              userID: currentUser?.id
            }
          })
        "
      >
        {{ t('ratingScale') }}
        <span class="sr-only">{{ t('srOpenNewTab') }}</span>
      </router-link>
    </div>
    <div v-if="!canRate" class="py-14 text-center">
      <p class="text-blue-darkest">{{ t('watchAllVideosNote') }}</p>
    </div>

    <div v-else>
      <ul class="grid lg:grid-cols-7 grid-cols-4 gap-10 mt-8">
        <li
          v-for="(score, i) in scale"
          :key="i"
          class="flex flex-col items-center text-center"
        >
          <button
            class="score-button flex items-center justify-center"
            :class="{ 'active-score': wrappedScore === i + 1 }"
            @click="emitScore(i + 1)"
            :disabled="disabled"
          >
            {{ i + 1 }}
          </button>
          <span>{{ score.anchor }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/runtime-core';
import { programStore } from '@/store/program';
import { useI18n } from 'vue-i18n';
import { logEvent } from '@/services/amplitude';
import { useStore } from '@/store/lib/store';
import { authStore } from '@/store/auth';

export default defineComponent({
  name: 'RatingInput',
  props: {
    comment: { type: String as PropType<string | null> },
    score: { type: Number as PropType<number | null> },
    canRate: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  emits: ['update:comment', 'update:score'],
  setup(props, { emit }) {
    const program = useStore(programStore);
    const auth = useStore(authStore);
    const i18n = useI18n();

    const currentUser = computed(() => auth.user);
    const scale = computed(() => program.currentTest?.scale);

    const wrappedComment = computed(() => props.comment ?? undefined);
    const wrappedScore = computed(() => props.score ?? undefined);

    function emitComment(event: Event) {
      emit('update:comment', (event.target as HTMLTextAreaElement).value || null);
    }

    function emitScore(value: number) {
      emit('update:score', props.score === value ? null : value);
    }

    return {
      t: i18n.t,
      currentTest: computed(() => program.currentTest),
      currentUser,
      scale,
      logEvent,
      wrappedComment,
      wrappedScore,
      emitComment,
      emitScore
    };
  }
});
</script>

<style scoped lang="postcss">
.score-button {
  @apply w-12 h-12 rounded-full border border-gray-light transition-colors;

  &:not([disabled]):hover {
    @apply bg-gray-lighter;
  }

  &:focus {
    @apply outline-none;
  }

  &:focus-visible {
    @apply outline-none ring-2 ring-blue;
  }

  /* Styling does not apply unless :moz-focusring is separated from :focus-visible, which is why it is repeated below */
  &:-moz-focusring {
    @apply outline-none ring-2 ring-blue;
  }

  &:disabled {
    @apply border border-gray-light text-gray-darker bg-gray-lighter cursor-not-allowed;
  }
}

.active-score {
  @apply text-white bg-blue border-blue;

  & + span {
    @apply text-blue-darkest;
  }

  &:not([disabled]):hover {
    @apply bg-blue;
  }

  &:disabled {
    @apply cursor-not-allowed text-white bg-gray-dark;
  }
}
.textarea {
  @apply border border-gray-light block w-full h-36 p-2 mt-3 mb-6 rounded;

  &:not([disabled]):hover {
    @apply outline-none ring-1 ring-blue;
  }

  &:focus {
    @apply outline-none;
    box-shadow: 0 0 6px #4c55a5;
  }

  &::placeholder {
    @apply text-gray-dark opacity-100;
  }

  &::-moz-placeholder {
    @apply text-gray-dark opacity-100;
  }

  &:disabled {
    @apply bg-white resize-none;
    &::placeholder {
      @apply opacity-0;
    }
    &::moz-placeholder {
      @apply opacity-0;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "comments": "Comments",
    "commentsPlaceholder": "Enter comments here (2000 character limit)",
    "ratingScale": "Rating scale explained",
    "scoreTitle": "Communication, self-reflection and motivation score",
    "srOpenNewTab": "(opens in a new tab)",
    "watchAllVideosNote": "Please finish watching all videos before submitting a score."
  },
  "fr": {
    "comments": "Commentaires",
    "commentsPlaceholder": "Entrez vos commentaires ici (2 000 caractères maximum)",
    "ratingScale": "Fonctionnement de l'échelle d'évaluation",
    "scoreTitle": "Score de communication, d'auto-réflexion et de motivation",
    "srOpenNewTab": "(ouvrir dans un nouvel onglet)",
    "watchAllVideosNote": "Veuillez visionner toutes les vidéos avant de soumettre un score."
  }
}
</i18n>
