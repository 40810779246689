<template>
  <a-collapsible-card
    :fixed="!isCurrentUserRating && !isRated"
    :expanded="isCurrentUserRating || isEditing || expandCardAfterEdit"
  >
    <h2 class="text-xl font-bold text-blue-darkest">
      <span>{{ t('reviewer') }}: </span>
      <span>{{ rater?.profile?.name }}</span>
      <span v-if="isCurrentUserRating"> ({{ t('you') }}) </span>
    </h2>
    <div class="mt-4 text-base">
      <p v-if="isRated">
        <span>{{ t('reviewed') }}</span>
        <span class="ml-6" v-if="editedBy !== undefined">
          {{ t('lastEditedBy') }} {{ editedBy.profile?.name }}
        </span>
      </p>
      <p v-else>{{ t('waitingForReview') }}</p>
    </div>

    <template #content>
      <button
        v-if="isSchoolAdmin && !isEditing && isRated"
        class="link focus:outline-none mb-2"
        @click="isEditing = true"
      >
        <div class="flex items-center">
          <a-edit-icon />
          <span class="ml-1">{{ t('edit') }}</span>
        </div>
      </button>
      <RatingInput
        :canRate="canScore"
        :disabled="isRated && !isEditing"
        v-model:comment="comment"
        v-model:score="score"
      />

      <div v-if="!isRated" class="flex flex-col">
        <a-alert v-if="showSubmitAlert" variant="warning" class="mt-4">
          <div class="prose" v-html="t('submitAlert')"></div>
        </a-alert>
        <a-button
          @click="submitRating()"
          :disabled="score === null || !canRate"
          :variant="showSubmitAlert ? 'danger' : 'primary'"
          class="ml-auto mt-8 mb-1"
        >
          {{ t('submit') }}
        </a-button>
      </div>
      <div v-else-if="isEditing" class="flex mt-8">
        <div class="ml-auto">
          <a-button variant="outline" @click="cancelEditing()">
            {{ t('cancel') }}
          </a-button>
          <a-button @click="updateRating()" :disabled="!hasRatingChanged" class="ml-5">
            {{ t('update') }}
          </a-button>
        </div>
      </div>
    </template>
  </a-collapsible-card>
</template>

<script lang="ts">
import {
  computed,
  DeepReadonly,
  defineComponent,
  PropType,
  ref,
  toRef,
  watch,
  watchEffect
} from '@vue/runtime-core';
import { Rating } from '@/interfaces/interfaces';
import { useStore } from '@/store/lib/store';
import { authStore } from '@/store/auth';
import { programStore } from '@/store/program';
import { useI18n } from 'vue-i18n';
import { logEvent } from '@/services/amplitude';
import { differenceInMinutes } from 'date-fns';

import RatingInput from '@/components/program/RatingInput.vue';
import { injectToasts } from '../common/toasts/toasts';

export default defineComponent({
  name: 'AssignedRatingCard',
  props: {
    submissionId: { type: String, required: true },
    rating: {
      type: Object as PropType<DeepReadonly<Rating>>,
      required: true
    },
    canRate: { type: Boolean, default: false }
  },
  components: {
    RatingInput
  },
  emits: ['ratingSubmitted'],
  setup(props, { emit }) {
    const auth = useStore(authStore);
    const program = useStore(programStore);
    const i18n = useI18n();
    const toasts = injectToasts();

    const comment = ref<string | null>(null);
    const score = ref<number | null>(null);

    const isEditing = ref(false);
    const showSubmitAlert = ref(false);
    const expandCardAfterEdit = ref(false);

    const currentUser = computed(() => auth.user);
    const isSchoolAdmin = computed(() => auth.isSchoolAdmin);

    const rater = computed(() => program.raters?.find(r => props.rating.userId === r.id));
    const isRated = computed(() => props.rating.score !== null);
    const isCurrentUserRating = computed(
      () => currentUser.value !== null && currentUser.value.id === props.rating.userId
    );

    const editedBy = computed(() => {
      if (
        props.rating.lastEditedByUserId !== undefined &&
        props.rating.lastEditedByUserId !== null
      ) {
        const editor = program.raters?.find(
          r => r.id === props.rating.lastEditedByUserId
        );

        return editor;
      }
    });

    const canScore = computed(
      () =>
        (props.canRate && isCurrentUserRating.value) || isEditing.value || isRated.value
    );

    const hasRatingChanged = computed(
      () =>
        props.rating.comment !== comment.value ||
        (props.rating.score !== score.value && score.value !== null)
    );

    async function updateRating() {
      if (score.value !== null && hasRatingChanged.value) {
        await program.scoreRating.run({
          rating: props.rating,
          score: score.value,
          comment: comment.value
        });

        if (program.scoreRating.error) {
          toasts.push({ title: i18n.t('submitError'), variant: 'danger' });
          throw program.scoreRating.error;
        }

        isEditing.value = false;
        expandCardAfterEdit.value = true;
      }
    }

    async function submitRating() {
      if (score.value === null) {
        throw new Error('Cannot submit a rating without a score');
      }

      if (!isSchoolAdmin.value && !showSubmitAlert.value && !isEditing.value) {
        showSubmitAlert.value = true;
        return;
      }

      await program.scoreRating.run({
        rating: props.rating,
        score: score.value,
        comment: comment.value
      });

      if (program.scoreRating.error) {
        toasts.push({ title: i18n.t('submitError'), variant: 'danger' });
        throw program.createScoredRating.error;
      }

      toasts.push({ title: i18n.t('submitSuccess'), variant: 'success' });
      emit('ratingSubmitted');

      const currentSubmission = program.submissions?.find(
        s => s.id === props.submissionId
      );

      logEvent({
        eventName: 'SS_ASSIGNED_APP_RATING',
        userProperties: {
          userID: auth.user?.id
        },
        eventProperties: {
          applicantUserId: currentSubmission?.user?.id,
          hasComment: comment.value !== null,
          snapshotScore: score.value,
          reviewerUserId: currentUser.value?.id,
          timeToRate: differenceInMinutes(new Date(), new Date(props.rating.createdAt))
        }
      });
    }

    function cancelEditing() {
      isEditing.value = false;
      comment.value = props.rating.comment;
      score.value = props.rating.score;
    }

    watch(toRef(props, 'submissionId'), () => {
      isEditing.value = false;
      showSubmitAlert.value = false;
      expandCardAfterEdit.value = false;
      score.value = props.rating.score;
      comment.value = props.rating.comment;
    });

    watchEffect(() => {
      score.value = props.rating.score;
      comment.value = props.rating.comment;
    });

    return {
      t: i18n.t,
      currentUser,
      isSchoolAdmin,
      isCurrentUserRating,
      currentTest: computed(() => program.currentTest),
      rater,
      comment,
      score,
      isRated,
      isEditing,
      showSubmitAlert,
      canScore,
      hasRatingChanged,
      editedBy,
      submitRating,
      updateRating,
      cancelEditing,
      expandCardAfterEdit
    };
  }
});
</script>

<i18n>
{
  "en": {
    "cancel": "Cancel",
    "edit": "Edit",
    "lastEditedBy": "Last edited by",
    "reviewed": "Reviewed",
    "reviewer": "Reviewer",
    "submit": "Submit and go to next applicant",
    "submitAlert": "<h4>Do you want to submit your review?</h4><p>Select submit review to confirm and go to the next applicant. You cannot edit a review once submitted.</p>",
    "submitError": "Failed to submit",
    "submitSuccess": "Submitted successfully",
    "update": "Update",
    "waitingForReview": "Waiting for review",
    "you": "you"
  },
  "fr": {
    "cancel": "Annuler",
    "edit": "Modifier",
    "lastEditedBy": "Dernière modification effectuée par",
    "reviewed": "Évalué",
    "reviewer": "Examinateur :",
    "submit": "Soumettre et aller au candidat suivant",
    "submitAlert": "<h4>Voulez-vous soumettre votre évaluation ?</h4><p>Sélectionnez soumettre pour confirmer et aller au candidat suivant. Vous ne pouvez pas modifier une évaluation une fois que vous l'avez soumise.</p>",
    "submitError": "Non soumis",
    "submitSuccess": "Soumis avec succès",
    "update": "Mettre à jour",
    "waitingForReview": "En atttente d'évaluation",
    "you": "moi"
  }
}
</i18n>
