export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "lastEditedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last edited by"])},
        "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit and go to next applicant"])},
        "submitAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Do you want to submit your review?</h4><p>Select submit review to confirm and go to the next applicant. You cannot edit a review once submitted.</p>"])},
        "submitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to submit"])},
        "submitSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted successfully"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
        "waitingForReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for review"])},
        "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you"])}
      },
      "fr": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
        "lastEditedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière modification effectuée par"])},
        "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évalué"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examinateur :"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre et aller au candidat suivant"])},
        "submitAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Voulez-vous soumettre votre évaluation ?</h4><p>Sélectionnez soumettre pour confirmer et aller au candidat suivant. Vous ne pouvez pas modifier une évaluation une fois que vous l'avez soumise.</p>"])},
        "submitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non soumis"])},
        "submitSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumis avec succès"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
        "waitingForReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En atttente d'évaluation"])},
        "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moi"])}
      }
    }
  })
}
