
import { TestQuestion, Answer } from '@/interfaces/interfaces';
import { computed, DeepReadonly, defineComponent, PropType, ref, watch } from 'vue';
import { useI18n } from '@/services/i18n';
import VideoPlayer from '@/components/common/VideoPlayer.vue';

export default defineComponent({
  name: 'Carousel',
  components: {
    VideoPlayer
  },
  props: {
    answers: {
      required: true,
      type: Array as PropType<Answer[]>
    },
    videoUrls: {
      required: true,
      type: Array as PropType<Array<{ fileName: string; url: string }>>
    },
    testQuestions: {
      required: true,
      type: Array as PropType<DeepReadonly<TestQuestion[]>>
    }
  },
  emits: ['answerWatched'],

  setup(props) {
    const currentSlide = ref(0);

    const videoPlayers: Array<InstanceType<typeof VideoPlayer>> = [];

    function setVideoPlayerInstance(el: object | null, i: number) {
      videoPlayers[i] = el as InstanceType<typeof VideoPlayer>;
    }

    function getQuestionText(textQuestionId: string) {
      const questionObj = props.testQuestions.find(t => t.id === textQuestionId);

      return questionObj?.question.text;
    }

    function getVideoUrl(fileName: string) {
      const videoObj = props.videoUrls.find(v => v.fileName === fileName);

      return videoObj?.url;
    }

    const augmentedAnswers = computed(() => {
      return props.answers.map(a => ({
        answer: a,
        questionText: getQuestionText(a.testQuestionId),
        videoUrl: getVideoUrl(a.videoFilename)
      }));
    });

    const amountToShift = computed(
      () => `translateX(-${(100 / augmentedAnswers.value.length) * currentSlide.value}%`
    );

    const transitioning = ref(false);

    watch(currentSlide, (newValue, oldValue) => {
      videoPlayers[oldValue].pause();
      transitioning.value = true;
    });

    watch(props, () => {
      currentSlide.value = 0;
    });

    function onTransitionFinished() {
      transitioning.value = false;
    }

    function nextSlide() {
      currentSlide.value === augmentedAnswers.value.length - 1
        ? (currentSlide.value = 0)
        : currentSlide.value++;
    }

    function previousSlide() {
      currentSlide.value === 0
        ? (currentSlide.value = augmentedAnswers.value.length - 1)
        : currentSlide.value--;
    }

    return {
      t: useI18n().t,
      currentSlide,
      amountToShift,
      nextSlide,
      previousSlide,
      onTransitionFinished,
      transitioning,
      augmentedAnswers,
      setVideoPlayerInstance
    };
  }
});
