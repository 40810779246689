export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit and go to next applicant"])},
        "submitAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Do you want to submit your review?</h4><p>Select submit review to confirm and go to the next applicant. You cannot edit a review once submitted.</p>"])},
        "submitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to submit"])},
        "submitSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted successfully"])}
      },
      "fr": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre et aller au candidat suivant"])},
        "submitAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Voulez-vous soumettre votre évaluation ?</h4><p>Sélectionnez soumettre pour confirmer et aller au candidat suivant. Vous ne pouvez pas modifier une évaluation une fois que vous l'avez soumise.</p>"])},
        "submitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non soumis"])},
        "submitSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumis avec succès"])}
      }
    }
  })
}
