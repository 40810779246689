export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
        "commentsPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter comments here (2000 character limit)"])},
        "ratingScale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale explained"])},
        "scoreTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication, self-reflection and motivation score"])},
        "srOpenNewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(opens in a new tab)"])},
        "watchAllVideosNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please finish watching all videos before submitting a score."])}
      },
      "fr": {
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
        "commentsPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez vos commentaires ici (2 000 caractères maximum)"])},
        "ratingScale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnement de l'échelle d'évaluation"])},
        "scoreTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score de communication, d'auto-réflexion et de motivation"])},
        "srOpenNewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ouvrir dans un nouvel onglet)"])},
        "watchAllVideosNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez visionner toutes les vidéos avant de soumettre un score."])}
      }
    }
  })
}
