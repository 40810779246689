export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "questionTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Question ", _interpolate(_named("questionNum")), " of ", _interpolate(_named("totalQuestions"))])},
        "srCarouselLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question and video response slider"])},
        "srNextSlideLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next slide"])},
        "srPreviousSlideLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous slide"])},
        "srSlideIndicatorLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Go to slide ", _interpolate(_named("num"))])}
      },
      "fr": {
        "questionTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Question ", _interpolate(_named("questionNum")), " sur ", _interpolate(_named("totalQuestions"))])},
        "srCarouselLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curseur questions et réponses vidéos"])},
        "srNextSlideLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diapositive suivante"])},
        "srPreviousSlideLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diapositive précédente"])},
        "srSlideIndicatorLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aller à la diapositive ", _interpolate(_named("num"))])}
      }
    }
  })
}
